const ServicesData = [
  {
    id: 1,
    title: "Web Design",
    desc: "Learn how to use Digma UI like a PRO",
    cover: "./assets/services/services-1.jpg",
  },
  {
    id: 2,
    title: "Web Development",
    desc: "Learn how to use Reactjs Javascript framework UI like a PRO. .",
    cover: "./assets/services/services-2.jpg",
  },
  {
    id: 3,
    title: "Photography",
    desc: "Learn how to use photoshop UI like a PRO. .",
    cover: "./assets/services/services-3.jpg",
  },
  {
    id: 4,
    title: "Live Support",
    desc: "Learn how to code  bot Python like a PRO. ",
    cover: "./assets/services/services-4.jpg",
  },
  {
    id: 5,
    title: "Content Writing",
    desc: "learn English Business like a Pro.",
    cover: "./assets/services/services-5.jpg",
  },
  {
    id: 6,
    title: "Timely Deliverables",
    desc: "Learn how to deliver quickly like a PRO. ",
    cover: "./assets/services/services-6.jpg",
  },
]

export default ServicesData
