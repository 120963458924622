import React from "react"

const Pages = () => {
  return (
    <>
      <section className='pagesContent'>
        <h1>Pages</h1>
      </section>
    </>
  )
}

export default Pages
