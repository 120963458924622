const Blogdata = [
  {
    id: 1,
    date: "27 JUN, 2022",
    title: "The Most Popular New top Business Apps",
    cover: "./assets/blog/blog1.jpg",
    category: "Technology",
  },
  {
    id: 2,
    date: "27 Jun, 2022",
    title: "The Best Marketing top Management Tools",
    cover: "./assets/blog/blog2.jpg",
    category: "Agency",
  },
  {
    id: 3,
    date: "22 JUN, 2022",
    title: "How to use React Hooks to create a Counter Component",
    cover: "./assets/blog/blog3.jpg",
    category: "IT",
  },
]

export default Blogdata
