const Data = [
  {
    id: 1,
    title: "Digital Marketing",
    desc: "The beginners guide to building an accessible web",
    cover: "./assets/work/work1.jpg",
  },
  {
    id: 2,
    title: "Digital Marketing",
    desc: "5 Popular Frontend Development Tools in 2021",
    cover: "./assets/work/work2.jpg",
  },
  {
    id: 3,
    title: "Digital Marketing",
    desc: "6 Awesome Tools that turn code snippets to images",
    cover: "./assets/work/work3.jpg",
  },
  {
    id: 4,
    title: "Digital Marketing",
    desc: " Debunking 5 Popular Coding Myths",
    cover: "./assets/work/work4.jpg",
  },
  {
    id: 5,
    title: "Digital Marketing",
    desc: "How to build a simple form with validation using yup and formik",
    cover: "./assets/work/work5.jpg",
  },
  {
    id: 6,
    title: "Digital Marketing",
    desc: "How to use React Hooks to create a Counter Component.",
    cover: "./assets/work/work6.jpg",
  },
]

export default Data
